import React, { useState, useEffect } from "react";
import {
  Card,
  Pagination,
  Form,
  Button,
  Dropdown,
  Alert,
} from "react-bootstrap";
import { Icon } from "@iconify/react";

import { logDOM } from "@testing-library/react";
import * as SERVICE from "../../services/index";
import * as API from "../../services/env";
import * as PIC from "../../services/env";

import { Oval } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function Fileupload() {
  const [preview, setPreview] = useState("");
  const [selectedFileType, setSelectedFileType] = useState("");
  const [selectedFile, setSelectedFile] = useState({
    label: "HSCode File",
    value: "hscodetables",
  });

  const [loader, setloader] = useState(false);

  const onChange = async (evt) => {
    const pic = evt.target.files[0];
    if (evt.target.files[0] != undefined) {
      const target = evt.target.files[0];
      setSelectedFileType(target);
      setPreview(URL.createObjectURL(evt.target.files[0]));
    }
  };

  // Function to handle selection
  const handleSelect = (label, value) => {
    setSelectedFile({ label, value });
  };

  const onSubmit = async () => {
    setloader(true)
    // Define allowed file types

    if (selectedFileType == "") {
      setloader(false)
      return toast.error("Please upload a valid file and try again.");
    }
    const jsonFileTypes = ["application/json"];
    const xlsxFileTypes = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];

    // Validate file type based on selected file type from the dropdown
    if (
      selectedFile.value === "hscodetables" ||
      selectedFile.value === "eccncodetables" ||
      selectedFile.value === "countryTables" ||
      selectedFile.value === "hazchemtables"
    ) {
      if (!xlsxFileTypes.includes(selectedFileType.type)) {
        setloader(false)
        toast(
          "Only XLSX files are allowed for HSCode, ECCN, Country, and Hazchem Files."
        );
        return;
      }
    }

    if (selectedFile.value === "eu" || selectedFile.value === "usa"|| selectedFile.value === "uk") {
      if (!jsonFileTypes.includes(selectedFileType.type)) {
        setloader(false)
        toast("Only JSON files are allowed for EU and USA Section Files.");
        return;
      }
    }

    let data = new FormData();
    data.append("attachments", selectedFileType);
    data.append("type", selectedFile.value);

    var requestOptions = {
      method: "POST",
      body: data,
    };

    const response = await fetch(
      `${API.BASE_URL}AdminuploadExtraFile`,
      requestOptions
    )
      .then((response) => response.json())
      .then(async (res) => {
        setloader(false)
        toast.success("File uploaded successfully!");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <React.Fragment>
      <Card className="mt-4">
        <Card.Body>
          <Form>
            <Form.Group controlId="formUsers" className="dropdownCustom">
              <Form.Label>Select File:</Form.Label>
              <Dropdown>
                <Dropdown.Toggle variant="primary" id="dropdown-static">
                  {selectedFile.label}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => handleSelect("HSCode File", "hscodetables")}
                  >
                    HSCode File
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleSelect("ECCN File", "eccncodetables")}
                  >
                    ECCN File
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      handleSelect("Country File", "countryTables")
                    }
                  >
                    Country File
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      handleSelect("Hazchem File", "hazchemtables")
                    }
                  >
                    Hazchem File
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleSelect("Eu Section File", "eu")}
                  >
                    Eu Section File
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleSelect("USA Section File", "usa")}
                  >
                    USA Section File
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleSelect("USA Section File", "uk")}
                  >
                    UK Section File
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>

            <Form.Label className="dropdownCustom">File Upload</Form.Label>
            <div class="formbold-file-input">
              <input type="file" name="file" id="file" onChange={onChange} />
              <label for="file">
               
                <div>
                  {selectedFileType == "" ? (
                    <>
                     <Icon icon="ep:upload-filled" />
                      <span class="formbold-drop-file"> Drop files here </span>
                      <span class="formbold-or"> Or </span>
                      <span class="formbold-browse"> Browse </span>
                    </>
                  ) :
                  preview && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Icon icon="solar:file-bold" style={{ color: 'red', opacity: 1 }} />
                      <span style={{ marginLeft: '10px' }}>{selectedFileType.name}</span> {/* Display file name */}
                    </div>
                  )
                  }
                </div>
              </label>
            </div>

            {loader == true ? (
              <Oval
                height="30"
                width="30"
                radius="30"
                color="#f2f5f3"
                ariaLabel="loading"
                wrapperStyle={{ marginLeft: 40, marginTop: 20 }}
                wrapperClass
              />
            ) : (
              <Button
                className="btn btn-primary px-4 mt-3 min_width140"
                onClick={(e) => {
                  onSubmit();
                }}
              >
                Submit
              </Button>
            )}
            <ToastContainer />
          </Form>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
