import React, { useState ,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Row,
  Col,
  Button ,
} from 'react-bootstrap';
import logo from "../assets/img/logo.svg";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as SERVICE from "../../services/index";
import * as API from "../../services/env";
export default function ForgotPassword() {
  const navigate = useNavigate();

  const [email, setemail] = useState("");
  const [emailError, setemailError] = useState(0);
  const [Submit, setSubmit] = useState(false);
  const [errorCount, seterrCount] = useState(0);
  const [url, seturl] = useState(null);
  const [domain, setdomain] = useState("");



  const onProfileValidation = async () => {
    let errorCount = 0;
    if (SERVICE.hasError(email)) {
      errorCount++;
      let msg = "Please enter your email.";
      setemailError(msg);
    } else {
      setemailError(null);
    }

    if (errorCount > 0) return seterrCount(errorCount);
    else seterrCount(0);
  };

  const onSubmit = async () => {
    setSubmit(true);
    onProfileValidation();
    if (errorCount > 0) return;
    // await detectWhitespace(domain.trim());

    let payload = {
      email: email,
    };

    await fetch(`${API.BASE_URL}AdminEmailNotification`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        email: email,

        link: `${url}/ResetPassword`,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status == true) {
          toast("Email confirmation mail send successfully sent");
        } else if (res.msg) {
          toast(res.msg);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    const protocol = window.location.protocol; 
    const host = window.location.host; 
    let path = window.location.pathname; 

 
    path = path.slice(0, path.lastIndexOf("/"));

    var fullUrl = `${protocol}//${host}${path}`;

    seturl(fullUrl);

    const arr = host.split(".")[0];
    if (arr.length > 0) setdomain(arr);
  }, []);
  useEffect(() => {
    onProfileValidation();
  }, [email]);
  return (
    <React.Fragment>
      <Row>
        <Col lg={6} className="loginform">
          <div className="loginmain_div maxwidth500 mx-auto">
            <div className="headlogin_div mb-4">
              <h2>Forgot Password</h2>
              <p>Please enter your email address to  receive an otp for password reset.</p>
            </div>
            <Form className="">
              <Form.Group className="mb-4 form-group">
                <Form.Label>Email Address</Form.Label>
                <Form.Control type="email" placeholder="Enter email address"  onChange={(e) => setemail(e.target.value)} // set the onChange handler
                  value={email}/>
                  
                {emailError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                    }}
                  >
                    {emailError}
                  </div>
                ) : null}
              </Form.Group>
              <Button
                as="input"
                value="Proceed"
                className="btn btn-primary w-100 mt-3"
                onClick={onSubmit}
              />
              {/* <ToastContainer /> */}
              {/* <Link to="/OTP"><Button as="input" type="submit" value="Proceed" className="btn btn-primary w-100 mt-3"/></Link> */}
              <p className="formfooter text-center mt-3">Back To <Link to="/">Login</Link></p>
            </Form>
          </div>
        </Col>
        <Col lg={6}>
          <div className="loginlogo_div">
            <Link><img src={logo} alt=""/></Link>
          </div>
          <ToastContainer />
        </Col>
      </Row>
    </React.Fragment>
  );
}
